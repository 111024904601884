.uploader--container {
    position: relative;
    background-color: #f1f1f1;
    border: 1px solid #c0c0c0;
}
.uploader--container .uploader--hidden {
    display: none;
}
.uploader--container .uploader--thumbnails-list {
    width: 100%;
}
.uploader--container .uploader--thumbnails-gallery {
    width: 100%;
    display: flex;
}
.uploader--container .uploader--alert-container {
    position: absolute;
    display: block;
    width: 100%;
    z-index: 20;
}
.uploader--container .uploader--alert-container .uploader--alert {
    color: #FFF;
    text-align: center;
    width: 100% !important;
    padding: 5px 0 !important;
    margin: 0 !important;
}
.uploader--container .uploader--alert-container .uploader--alert.success {
    background: #70b971;
}
.uploader--container .uploader--alert-container .uploader--alert.error {
    background: #b9595e;
}
.uploader--container .uploader--button-container {
    display: flex;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 10px;
    z-index: 10;
}
.uploader--container .uploader--button-container .uploader--button {
    display: block;
    margin: auto;
    padding: 10px 20px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #c0c0c0;
}
.uploader--container .uploader--button-container .uploader--button:hover {
    background: #c9c9c9;
    border: 1px solid #6d6d6d;
    cursor: pointer;
}
.uploader--container .uploader--default-message-container {
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
}
.uploader--container .uploader--default-message-container.uploader--dropzone-hover .uploader--default-message {
    color: #5a6d83;
}
.uploader--container .uploader--default-message-container.uploader--dropzone-hover .uploader--default-message.uploader--hidden {
    display: block;
}
.uploader--container .uploader--default-message-container .uploader--default-message {
    margin: auto;
}
.uploader--container .uploader--dropzone {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
}
.uploader--container .uploader--dropzone.uploader--dropzone-hover {
    background: rgba(179, 212, 252, 0.47);
    z-index: 15;
}
.uploader--container .uploader--thumbnails-container {
    position: relative;
    z-index: 15;
    margin-top: 35px;
}
.uploader--container .uploader--thumbnails-container .uploader--thumbnail {
    display: flex;
    position: relative;
    margin: 10px;
}
.uploader--container .uploader--thumbnails-container .uploader--thumbnail .uploader--thumbnail-info {
    margin: auto auto auto 0;
    display: flex;
}
.uploader--container .uploader--thumbnails-container .uploader--thumbnail .uploader--thumbnail-info .uploader--thumbnail-size {
    width: auto;
    display: block;
    font-size: 12px;
    margin: auto 0 auto 50px;
    color: #757171;
}
.uploader--container .uploader--thumbnails-container .uploader--thumbnail .uploader--thumbnail-remove {
    margin: 0;
    z-index: 15;
    font-size: 16px;
    display: block;
}
