@import "BBW-uploader.css";

//COLORS VARIABLES ==================================
$red: #b20038;

// MEDIA QUERIES ---------------------------------------------------

$mobile:"(max-width: 601px)";
$tablet:"(min-width: 601px) and (max-width: 991px)";
$tabletSmall:"(min-width: 600px) and (max-width: 670px)";
$desktopSmall:"(min-width: 991px) and (max-width: 1199px)";
$desktop:"(min-width: 991px)";
$desktopLarge:"(min-width: 1600px)";
$xl:"(min-resolution: 2dppx) and (min-width: 1300px)"; //4k

body {
  letter-spacing: .5px;
}

.container {
  padding-top: 50px;
  padding-bottom: 50px;
}

// LOGO ==================================
#sidebar-toggle {
  height: auto;
  width: auto;
  max-width: 50px;
  z-index: 999;
  padding: 5px;

  .picto-icon {
    display: block;
    height: auto;
    position: relative;
    left: 0;
    top: 0;
    width: auto;

    img {
      margin: 0 !important;
      max-width: 100%;
    }
  }
}

// ADDCLASS
.container-fluid {
  padding: 0;
}
.container, .container-fluid{

  @media screen and (min-width: 1200px) {
    &.wide {
      width: 100%;
      max-width: 1600px;
      @media #{$mobile}{
        padding: 0 15px;
      }
    }
    &.large{
      width: 100%;
      max-width: 1440px;
      @media #{$mobile}{
        padding: 0 15px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    &.wide {
      width: 100%;
      max-width: 900px;
      @media #{$mobile}{
        padding: 0 15px;
      }
    }
    &.large{
      width: 100%;
      max-width: 900px;
    }
  }

  > .row {
    margin: 0;
  }
}

// BACKOFFICE LANDING PAGE

.intro-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
  text-align: center;
  top: 65px;
  position: relative;

  .intro-content {
    margin : auto;
    h3 {
      text-transform: uppercase;
      margin: 0;
      font-weight: 200;
      font-size: 1.45em;
      letter-spacing: 2.5px;
    }
    img {
      max-width: 100px;
      margin-bottom: 25px;
    }
  }
}


nav.navbar {
  height: 65px;
  padding: 0;
  background-color: $red;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 9;

  @media #{$mobile}, #{$tablet}{
    overflow: visible;
  }

  .nav-icon {
    display: none;

    @media #{$mobile}, #{$tablet}{
      display: block;
    }

    svg {
      width: 65px;
      height: 65px;
      position: relative;
      top: -2px;
      cursor: pointer;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    path {
      fill: none;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
      -moz-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
      -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
      -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
      transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
      stroke-width: 22px;
      stroke-linecap: round;
      stroke: white;
      stroke-dashoffset: 0px;
      &#top, &#bottom {
        stroke-dasharray: 240px 950px;
      }
      &#middle {
        stroke-dasharray: 240px 240px;
      }
    }

    .cross path {
      &#top, &#bottom {
        stroke-dashoffset: -650px;
      }
      &#middle {
        stroke-dashoffset: -115px;
        stroke-dasharray: 1px 220px;
      }
    }

  }

  a.navbar-brand {
    position: relative;
    top: -2px;
    display: block;
    font-weight: 400;
    font-size: 1.2em;
    padding: 0;
    margin: 0 0 0 20px;
  }
  .navbar-collapse {
    transition: .3s all ease;
    height: 100%;

    @media #{$mobile}, #{$tablet}{
      transition: .4s all ease;
      pointer-events: none;
      height: auto;
      top: 65px;
      position: fixed;
      width: 100%;
      background: #95002f;
      padding: 20px;
      opacity: 0;
    }

    &.cross {
      @media #{$mobile}, #{$tablet}{
        pointer-events: auto;
        opacity: 1;
      }
    }

    ul {
      margin: 0 30px 0 auto !important;
      @media #{$mobile}, #{$tablet}{
        display: table;
        margin: 0 !important;
      }
      li {
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }
        a.nav-link {
          transition: .3s all ease;
          position: relative;
          top: -1px;
          color: rgba(255,255,255,.8);
          text-transform: uppercase;
          font-size: .8em;
          line-height: 1;
          padding: 0 0 8px 0;
          margin-top: 8px;

          @media #{$mobile}, #{$tablet}{
            padding: 10px 0;
            margin: 0;
          }

          &:after{
            transition: .3s all ease;
            content:'';
            background: white;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 1px;
            width: 0;
            //bottom: -10px;
            opacity: 0;
            bottom: 0;

            @media #{$mobile}, #{$tablet}{
              display: none;
            }
          }

          &:hover{
            color: rgba(255,255,255,1);
            &:after{
              width:25px;
              opacity: 1;
            }
          }
        }
      }
    }
    .logout {
      a {
        position: relative;
        top: -1px;
        transition: .3s all ease;
        text-transform: uppercase;
        font-size: .8em;
        line-height: 1em;
        color: rgba(255,255,255,.8);
        margin: auto;
        padding: 27px 25px;
        background: rgba(0,0,0,.15);
        text-decoration: none;

        @media #{$mobile}, #{$tablet}{
          padding: 0;
          background: transparent !important;
          margin-top: 30px;
        }

        &:hover{
          //color: rgba(255,255,255,1);
          background: rgba(0,0,0,.3);
          @media #{$mobile}, #{$tablet}{
            color: rgba(255,255,255,1);
          }
        }
      }
    }
  }
}


// BANNER TOP
.banner, .intro {
  min-height: 350px;
  height: 48vh;
  overflow: hidden;

  &.little {
    //min-height: 300px;
    //height: 44vh;
  }
}

header.parallax {
  background-size: cover;
  //background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100% - 65px);
  top: 65px;
  position: relative;

  .banner-overlay {
    width: 100%;
    height: 100%;
    position: relative;
    background: rgba(0,0,0,0.35);
    margin: 0;
    h1 {
      transition: .4s color ease, .4s filter ease;
      font-size: 4.5em;
      font-weight: lighter;
      line-height: 1.2;
      max-width: 1200px;
      margin: 0;
      opacity: 0;
      padding: 1em;
      position: absolute;
      bottom: 0;
      text-align: left;
      width: 100%;
      color: white !important;
      transform: translateY(40px);
      animation: fadinUp .8s ease 1s both running;

      &.hidden {
        color: rgba(255,255,255,0) !important;
        filter: blur(3px);
      }

      @media #{$tablet}{
        font-size: 3.5em;
      }

      @media #{$mobile}{
        font-size: 3em;
      }

      span {
        color: white !important;

        @media #{$mobile}{
          font-size: 42%;
        }
      }
    }
  }

}

@keyframes fadinUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.btn-primary {

}


/* --------------------------------------------------------------------------------
    TABLES
  ================================================================================= */

table {
  @extend form;
  font-size: .85em;
  text-align: left;
  border-spacing: 0;
  border-collapse: collapse;
  background: white;
  margin-bottom: 60px !important;
  border: 1px solid white !important;
  box-shadow: 0 25px 40px -15px rgba(0, 0, 0, 0.4);

  display: block;
  overflow-x: auto;

  label, legend {
    margin: 0 !important;
  }
  tr {
    display: table-row;
    width: auto;

    td, th{
      transition: .3s all ease;
      text-align: left;
      padding: 10px 15px !important;
      text-transform: capitalize;
      font-weight: 300;
      border: none !important;
      border-left: 1px solid white !important;
      outline: none;
      width: 100vw;
      display: table-cell !important;

      &:first-child {
        border-left: none !important;
      }
      &.sorting {
        padding-right: 33px !important;
        &:before {
          transition:.3s bottom ease;
          right: 18px !important;
        }
        &:after {
          transition: .3s bottom ease;
          right: 11px !important;
        }
        &:hover {
          &:before {
            bottom: 4px !important;
          }
          &:after {
            bottom: -4px !important;
          }
        }
      }
      a {
        color: $red;
      }
    }
  }

  thead{
    tr{
      background: #da1e59;
      color: white;
      text-transform: uppercase;
      font-weight: 300;
    }
    th {
      white-space: nowrap;
    }
  }

  tbody {
    &.sortable {
      cursor: move;
    }
    tr {
      //transition:.3s all ease;
      &:nth-of-type(odd) {
        background: #f2f2f2;
      }

      &:hover {
        background: #e9e9e9;
        /*td, th{
          color: white;
          a{
            color: white;
          }
        }*/
      }
      td {
        .btn-icon {
          vertical-align: baseline;
          line-height: 1;
          margin-right: 20px;

          &:nth-last-child(1) {
            margin-right:0 !important;
          }
        }
      }
    }
  }
  iframe {
    width: 100%;
    min-width: 185px;
  }
  &.table-work {
    tbody {
      tr{
        td {
          &:nth-of-type(2) {
            min-width: 140px;
          }
          &:nth-of-type(4) {
            min-width: 165px;
          }
          &:nth-of-type(5) {
            min-width: 300px;
          }
        }
      }
    }
  }
  &.table-category {
    tbody {
      tr{
        td {
          white-space: nowrap;
        }
      }
    }
  }

  table {
    box-shadow: none;
    font-size: 1em;
    margin: 10px 0 5px 0 !important;

    tr {
      border-bottom: solid 1px white;
      &.table-active {
        background-color: #f2f2f2;
        td {
          background-color: #f2f2f2;
        }
      }
      &:last-child {
        border: 0;
      }
    }
  }

  &.expertise {
    tr td:nth-of-type(2) {
      width: 64%;
    }
  }
  .ico {
    width: 12%;
    img {
      max-width: 28px;
      margin: auto;
      display: block;
    }
  }
}

.table-responsive {
  overflow: visible;
}

// BUTTONS
button {
  &[type="submit"] {
    position: relative;
    display: block;
    margin: 50px auto 0 auto;
    background: transparent;
    border: none;
    outline: none;
    padding: 0 50px;
    color: $red;
    font-weight: 400;
    font-size: 1.25em;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 1;

    &:before {
      content:'';
      transition: all 0.2s ease;
      height: 2px;
      width: 35px;
      background: $red;
      position: absolute;
      right: 0;
      top: 10px;
    }
    &:after {
      content:'';
      transition: all 0.2s ease;
      height: 2px;
      width: 35px;
      background: $red;
      position: absolute;
      left: 0;
      top: 10px;
    }

    &:hover {
      &:before {
        width: 15px;
        right: 20px;
      }
      &:after {
        width: 15px;
        left: 20px;
      }
    }
  }
}

.btn {
  &.btn-icon {
    transition:.4s all ease;
    padding:0 20px 0 0;
    box-shadow: none !important;
    font-weight: 400;
    font-size: 1em;
    position: relative;
    letter-spacing: .5px;
    //text-transform: uppercase;

    img{
      transition:.25s all ease;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 13px;
    }

    &.btn-edit {
      //color: #727292;
      color: #7986cb;
    }

    &.btn-delete {
      color: #F44336;
      line-height: 1;
    }

    &:hover {
      img {
        transform: translateY(-50%) scale(1.3);
      }
    }
  }
}

// CONTAINER + PAGE STYLE
.container {
  > h2, .h2 {
    display: table;
    font-size: 1.6em;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 25px;
    a {
      transition:.3s all ease;
      position: relative;
      color: black;
      text-decoration: none;
      padding-right: 28px;
      display: block;

      &:before {
        transition: .3s all ease;
        display: block;
        position: absolute;
        content: attr(data-title);
        color: #b20038;
        white-space: nowrap;
        width: 0;
        overflow: hidden;
        opacity: 1;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
      }

      span {
        transition:.3s all ease;
        position: absolute;
        top: 17px;
        right: 0;
        width: .75em;
        height: 1px;
        background: black;
        opacity: 1;

        &:after{
          transition:.25s all ease;
          content: '';
          width: 1px;
          height: 10px;
          background: black;
          transform: rotate(45deg);
          display: block;
          position: absolute;
          top: -1px;
          right: 8px;
          opacity: 0;
        }
        &:before{
          transition:.25s all ease;
          content: '';
          width: 1px;
          height: 10px;
          background: black;
          transform: rotate(-45deg);
          display: block;
          position: absolute;
          top: -8px;
          right: 8px;
          opacity: 0;
        }
      }

      &:hover {
        padding-right: 35px;

        &:before{
          width: 100%;
        }
        span {
          &:after, &:before{
            transition:.25s all ease .25s;
            right: 3px;
            opacity: 1;
            height: 10px;
          }
        }
      }
    }
  }
  > .btn {
    margin-bottom: 12px;
    text-transform: uppercase;
  }
  &.edit {
    form {
      margin-top: 70px;
    }
  }
}

.btn {
  &.btn-add {
    position: relative;
    z-index: 0;
    padding: .5em 1.2em;
    color: $red;
    font-size: .95em;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 0;
    //background: #9494b6;
    //background: #7986cb;
    background: transparent;
    transition: 0.3s all ease;

    &:before {
      content: '';
      z-index: -1;
      border-radius: inherit;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      transition: 0.3s all ease;

      border: solid 2px $red;
      opacity: 1;
      transform: scale(1);
    }

    &:after {
      content: '';
      z-index: -1;
      transition: 0.3s all ease;
      border-radius: inherit;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100%  - .5px);
      height: calc(100% - .5px);
      -webkit-backface-visibility: hidden;
      background: $red;

      opacity: 0;
      transform: scale(0.8);
    }

    span {
      font-size: 1.4em;
      line-height: 1;
      top: 1px;
      position: relative;

    }
    &:hover {
      color: white;
      &:before {
        //border-width: .5px;
        transform: scale(1.2);
        opacity: 0;
      }
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

//FORM
form {

  h2 {
    display: table;
    font-size: 1.6em;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 25px;
  }

  label, legend {
    display: block;
    margin-bottom: 0;

    &.required:after {
      color: $red;
      content: ' *';
      display: inline;
      font-size: 20px;
      position: absolute;
      //top: -5px;
      padding-left: 5px;
    }
  }

  .sub-label{
    outline: none !important;
    box-shadow: none !important;
    top: 45px;
    margin: 0;
    height: auto;
    font-size: .75em !important;
    left: 0;
  }

  .form-check {
    .bar {
      display: none !important;
    }
  }

  .select {
    margin-bottom: 25px;
    label, legend {
      outline: none !important;
      box-shadow: none !important;
      //top: 45px;
      margin: 0;
      height: auto;
      font-size: 1em;
      left: 0;
    }
  }

  select {
    transition:0.4s, all, ease;
    width: 100%;
    overflow: hidden;
    position:relative;
    box-shadow: none;
    padding: 10px 10px 10px 5px;
    background:url("../img/icons/select-arrow.svg") no-repeat right 0px center;
    background-size: 21px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    border: none !important;
    border-radius: 0;
    outline: none !important;
    cursor: pointer;
    border-bottom: solid 1px #aaaaaa !important;
    //min-height: 40px;
    font-size: 1em;
    font-weight: 400;
    color: black;

    option{
      &.parent-option {
        color: $red;
        text-transform: uppercase;
      }
      &.child-option {
        color: #1e1e1e;
        font-size: .9em;
      }
    }

    /*&:focus{
      border-bottom: solid 1px $red !important;
    }*/
    option{
      border: 0;
      outline: none;
      -webkit-appearance: none;
      font-size:16px;
      background:#ffffff;
    }
  }

  input {
    background: transparent !important;
    &[type=checkbox] {
      opacity: 0;
      z-index: -1;
      //display: none;
      visibility: visible;
      width: 1px;
      height: 1px;

      position: absolute;
      top: 32px;
      left: 10px;
    }

    &[type='checkbox']{
      + label, legend {
        width: auto;
        height: auto;
        position: relative;
        cursor: pointer;
        margin: 0 0 25px 0;
        display: table;

        .btn-check {
          transition: 0.3s all ease;
          position: relative;
          width: 20px;
          height: 20px;
          float: left;
          border: solid 1px $red;
          background: transparent;
          overflow: hidden;

          &:before {
            content: '';
            transition: 0.3s all ease;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            margin: 0;
            background: $red;
            transform: scale(0);
          }

          &:after {
            transition: 0.3s opacity ease;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: -20px;
            transform: translate(-50%, -50%);

            z-index: 9;
            background: url("../img/icons/checkbox.svg") center no-repeat;
            opacity:0;
          }
        }


        p {
          margin: 0;
          line-height: 1.3;
          padding-left: 30px;
        }
      }

      &:checked + label, legend {
        .btn-check {
          transition: 0.7s all ease;
          background: $red;

          &:before {
            transition: 0.3s all ease;
            transform: scale(1);
          }
          &:after {
            transition: 0.2s all ease .15s;
            opacity: 1;
            left: 50%;
          }
        }
      }

    }
  }

  textarea{
    margin-bottom: 0;
    min-height:110px;
    min-width: 100%;
    border: 0;
    border-bottom: 1px solid #aaaaaa;
    outline: none;
    padding: 10px 10px 10px 5px;

    &:focus{
      min-height:110px;
    }
  }
  .textarea {
    label, legend {
      line-height: 1;
    }
  }


  .form-group {
    position:relative;
    margin-bottom:45px;

    p.text-checkbox{
      margin: 20px 0 10px 0;
      line-height: 30px;
      padding:0;
      display: table;

      @media screen and (max-width: 465px) {
        line-height: inherit;
      }
    }

    input {
      font-size:1em;
      padding:10px 10px 10px 5px;
      display:block;
      border:none;
      border-bottom: 1px solid #aaaaaa !important;
      border-radius: 0;
      width: 100%;
      outline:none !important;
      box-shadow: none !important;

    }

    /* LABEL ======================================= */
    label, legend {
      color: #181818;
      font-size: 1.2em !important;
      font-weight:normal;
      //position:absolute;
      //pointer-events:none;
      //left:5px;
      //top:10px;
      //transition:0.2s ease all;
      //-moz-transition:0.2s ease all;
      //-webkit-transition:0.2s ease all;
    }

    /* BOTTOM BARS ================================= */
    .bar {
      position:relative;
      display:block;
      width:100%;
    }

    .bar {
      position: absolute;
      display: block;
      width: 100%;
      bottom: 0;
      left: 0;
      height:1px;

      &:before, .bar:after {
        content:'';
        transition:.3s ease all;
        position:absolute;
        height:100%;
        width:0;
        background: #b20038;
      }
      &:before {
        left:0;
      }
      &:after {
        right:0;
      }
    }

    /* active state */
    input:focus ~ .bar:before, input:focus ~ .bar:after {
      width:100%;
    }

    // EDITOR STYLING
    .cke_button, .cke_combo_button {
      transition: .1s background ease;
      cursor: pointer;
    }

  }

  .editor-container {
    .form-group {
      label, legend {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-bottom: 5px;
      }
    }
  }

  .browse-file {
    .form-group {
      padding-bottom: 25px;

      > label, legend {

      }
      .custom-file {

        display: block;
        height: auto;
        input {
          outline:none !important;
          box-shadow: none !important;
          padding: 8px;
          height: 40px;
          opacity: 1;
          font-size: .75em;
          cursor: pointer;
        }
        label, legend {
          outline:none !important;
          box-shadow: none !important;
          padding: 0;
          margin: 0;
          top: 0;
          height: 40px;
          border: 0;
          &:after {
            transition: .3s all ease;
            background: none;
            border-radius: 0;
            padding: 8px;
            font-size: 1em;
            height: auto;
          }
          &:before {
            content: '';
            position: absolute;
            top: 13px;
            right: 55px;
            bottom: 0;
            z-index: 3;
            display: block;
            height: 15px;
            padding: .375rem .75rem;
            line-height: 1.5;
            border-left: solid 1px grey;
          }
        }

        &.focused {
          input {

          }
          label, legend {
            &:after {
              color: #00dc5b;
            }
          }
        }
      }
    }

    .current--container{
      margin-top: 1rem;
      border: 1px solid #adadad;
      padding: 1rem;
      border-radius: 5px;
      background: #eee;

      .current--label{
        border-bottom: 1px solid #adadad;
        margin-bottom: 1rem;
      }

      .current--images{
        .innerContent{
          display: flex;
          img{
            margin: auto;
            max-width: 100%;
            max-height: 150px;
          }
        }
      }
    }
  }

  .list-check {
    margin-top: 25px;
    >label, legend {
      margin-bottom: 20px;
    }

    .list {
      margin-top: 20px;
    }
  }

  .radio-container {
    margin-bottom: 25px;
    > p {
      margin-bottom: 10px;
    }
    .radio {
      transition: .2s all ease;
      display: block;
      margin-bottom: 10px;
      position: relative;
      padding-left: 25px;
      user-select: none;
      line-height: 1.1;
      cursor: pointer;

      span {
        transition: .2s all ease;
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: white;
        border: solid 1px $red;
        border-radius: 50%;

        &:after {
          content: '';
          transition: .2s all ease;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0);
          width: 50%;
          height: 50%;
          border-radius: 50%;
          background: $red;
        }
      }

      input {
        transition: .3s all ease;
        position: absolute;
        opacity: 0;
        &:checked ~ span {
          &:after {
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }

      &:hover {
        input ~ span {
          background-color: #f5f5f5;
        }
      }
    }

  }
}

.add-work-list {
  .btn.btn-add {
    transition: all 0.3s ease;
    color: #b20038;
    font-size: .95em;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 0;
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);

    vertical-align: top;
    padding: .18em 0;
    margin-bottom: 0;

    border: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    &:after{
      display: none;
    }
    &:before{
      display: none;
    }
    &:hover{
      opacity: .85;
    }
  }

  .btn-delete {
    top: -1px;
    line-height: 1;
    margin-left: 10px;
    padding-right: 18px;
  }
  >div {
    position: relative;
  }
  input {
    min-height: 40px;
  }
  ul {
    position: relative;
  }
}

.draggable {
  padding: 0 15px !important;
  margin-bottom: 25px;
  list-style: none;

  &--item {
    padding: 5px 0;
    position: relative;
    font-family: "Lato", Arial, sans-serif !important;
    font-size: 1em;
    cursor: grab;
    cursor: -webkit-grab;
    border: 1px solid #aaa;
    display: flex;

    &:active {
      cursor: grabbing;
      cursor: -webkit-grabbing;
    }

    .btn-delete {
      margin: auto 10px auto auto;
      font-size: 13px;
    }

    .draganddrop {
      border-top: 2px solid #b20038;
      width: 18px;
      padding-top: 2px;
      margin: auto 12px;

      &:before,
      &:after {
        display: block;
        content: "";
        border-top: 2px solid $red;
        padding-top: 2px;
      }
    }

    .draganddrop:before,
    .draganddrop:after {
      display: block;
      content: "";
      border-top: 2px solid $red;
      padding-top: 2px;
    }
  }
}
.sortable-chosen:active {
  background: #f3f3f3;
  cursor: grabbing;
  cursor: -webkit-grabbing;
}


// LOGIN SECTION ---------------------------------------------------
#login {
  position: relative;
  width: 100%;
  height: calc(100vh - 65px);
  top: 65px;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top:0;
    left: 0;
  }
  .full-bg {
    position: absolute;
    top:0;
    left: 0;

    width: 100%;
    height: 100%;
    overflow: hidden;

    > img {
      filter: blur(10px);

      transform: translate(-50%, -50%) scale(1.05);
      position: relative;
      min-height: 100%;
      min-width: 100%;
      display: block;
      max-height: 1200px;
      max-width: inherit;
      overflow: hidden;
      left: 50%;
      top: 50%;
      z-index: -1;
    }
  }


  > .container {
    z-index: 2;
    position: relative;
    height: 100%;
    display: flex;

    .center {
      margin: auto;
      .content-form {
        width: 300px;
        max-width: 100%;
        margin: auto;
        form {

        }
      }
    }
  }
  h2 {
    display: table;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto auto 45px auto;
    color: white;
  }
  input, label, legend {
    color: white;
  }
  .form-group {
    margin-bottom: 35px;
  }
  button[type="submit"] {
    color: white;
    &:after, &:before {
      background: white;
    }
  }

}

// MODAL ---------------------------------------------------
.modal {
  cursor: pointer;
  .modal-dialog {
    height: 100%;
    display: flex;
    margin: auto;
    .modal-content {
      margin: auto;
      cursor: default;
      border-radius: 0;
      border: none;
      overflow: hidden;
      box-shadow: none !important;
      button {
        outline: none;
      }
      .modal-header{
        position: relative;
        border: none;

        .modal-title {
          font-weight: 300;
          text-transform: uppercase;
          color: $red;
        }
      }
      .modal-body {
        position: relative;
        border: none;
        font-size: .9em;
      }
      .modal-footer {
        position: relative;
        border: none;
        .btn {
          &.btn-danger{
            @extend .btn-add;
            border: 0;
            margin-bottom: 0;
            padding: 0px 15px;
          }
          &.btn-secondary{
            @extend .btn-add;
            border: 0;
            color: #424242;
            margin-bottom: 0;
            padding: 0px 15px;
            &:before {
              border-color: #424242;
            }
            &:after {
              background: #424242;
            }
            &:hover{
              color: white;
            }
          }
        }
      }
      .modal-header, .modal-body, .modal-footer {
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom:0;
          left: 50%;
          transform: translateX(-50%);
          height: 1px;
          width: calc(100% - 32px);
          background: #dee2e6;
        }
      }
    }
  }
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
  from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
  from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}


/* --- SCROLLBAR - STYLE --- */
::-webkit-scrollbar-track {
  box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
  border-radius:0;
  background-color: $red;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: $red;
}

::-webkit-scrollbar-thumb {
  box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
  border-radius:0;
  background-color: rgba(255,255,255,.8);
}

// Firefox

:root, *{
  scrollbar-color: $red #e4e4e4;
  scrollbar-width: thin;
}